import { defineStore } from 'pinia'

export const useConfigStore = defineStore('configStore', {
  state: () => ({
    customPrompt: '',
    originalPrompt: '',
    useCustomPrompt: false,
    selectedModel: 'GPT-4',
    database: '',
    numRelevantDocs: 5,
  }),
  actions: {
    setOriginalPrompt(originalPrompt: string) {
      this.originalPrompt = originalPrompt
    },
    setPrompt(newPrompt: string) {
      this.customPrompt = newPrompt
    },
    setUseCustomPrompt(usePrompt: boolean) {
      this.useCustomPrompt = usePrompt
    },
    setSelectedModel(model: string) {
      this.selectedModel = model
    },
    setDatabase(db: string) {
      this.database = db
    },
    setNumRelevantDocs(nr: number) {
      this.numRelevantDocs = nr
    },
  },
})
