import type {
  Bible,
  BibleTestament,
  BibleBook,
  BibleChapter,
  BibleChapterParagraph,
  FormattedBibleVerse,
} from 'global'
import { defineStore } from 'pinia'

// import { setData } from 'nuxt-storage/local-storage'
import { BibleVersionIds } from '~/enums'

export const useBibleStore = defineStore('bibleStore', {
  state: () => ({
    bible: {} as Bible,
    books: [] as BibleBook[],
    isBibleLoaded: false,
    currentBookName: 'Genesis',
    currentBookId: 'GEN',
    currentChapterId: 'GEN.1',
    currentChapter: '1',
    currentChapterContent: [] as BibleChapterParagraph[],
    selectedVerses: [] as FormattedBibleVerse[],
    isLoading: false,
    scriptureRegex: /(\b[123]?\s?[A-Za-zë]+\b)\s+(\d+)(?::(\d+))?/gi,
  }),
  actions: {
    setDefaultBibleBookAndChapter() {
      const book = this.books[0]
      this.currentBookId = book?.id
      this.currentBookName = book?.name
      const chapter = book.chapters[0]
      this.currentChapter = chapter.number
      this.currentChapterId = chapter.id
    },
    setCurrentBibleBook(bookName: string) {
      const book = this.books?.find((book) => book?.name === bookName)
      if (!book) {
        throw new Error('No book found')
      }
      this.currentBookId = book?.id
      this.currentBookName = book?.name
      // setData('currentBibleBook', this.currentBookName, 24, 'h')
    },
    setCurrentBibleChapter(chapterNumber: string) {
      const currentBook = this.books?.find(
        (book) => book.id === this.currentBookId,
      )
      const chapter = currentBook?.chapters?.find(
        (chapter) => chapter.number === chapterNumber,
      )
      if (chapter) {
        this.currentChapter = chapterNumber
        this.currentChapterId = chapter.id
        // setData('currentBibleChapter', chapterNumber, 24, 'h')
      }
    },
    selectVerse(verse: FormattedBibleVerse) {
      this.selectedVerses.push(verse)
      this.selectedVerses.sort((a, b) => {
        return parseInt(a.verseNumber) - parseInt(b.verseNumber)
      })
    },
    deselectVerse(verse: FormattedBibleVerse) {
      this.selectedVerses = this.selectedVerses.filter(
        (v) => v.verseNumber !== verse.verseNumber,
      )
    },
    async getBible(bibleVersionId = BibleVersionIds.NBV21, brand = 'NBG') {
      if (this.bible.id === bibleVersionId) {
        return
      }
      try {
        const { data } = await useFetch(`/api/bible/bible-meta`, {
          method: 'GET',
          params: {
            bibleId: bibleVersionId,
            brand,
          },
          onRequestError: (error) => {
            console.log(error)
          },
        })
        const newBible = data?.value as unknown as Bible
        this.bible = newBible
        this.books = this.bible?.testaments?.flatMap(
          (testament: BibleTestament) => testament.books,
        )
      } catch (error) {
        console.log(error)
      }
    },
    getChaptersOfBook(bookName: string): BibleChapter[] {
      return this.books.flatMap((book) => {
        return book?.name === bookName ? book?.chapters : []
      })
    },
    // Returns the chapter content and fetches it if it's not available
    async setCurrentChapterContent(brand = 'NBG') {
      this.isLoading = true
      const book = this.books?.find((b) => b.id === this.currentBookId)
      if (!book) {
        throw new Error('No book found')
      }

      const chapterIndex = book.chapters?.findIndex(
        (chapter) => chapter.id === this.currentChapterId,
      )

      // If no content is available, fetch it
      if (!book.chapters[chapterIndex].content) {
        try {
          this.selectedVerses = [] // Reset selected paragraphs
          const { data } = await useFetch(`/api/bible/chapter`, {
            method: 'GET',
            params: {
              bibleId: this.bible.id,
              chapter: this.currentChapterId,
              brand,
            },
            onRequestError: (error) => {
              console.log(error)
              throw new Error('Failed to fetch chapter content')
            },
          })
          const fetchedChapter = data?.value?.chapter
          book.chapters[chapterIndex].content =
            fetchedChapter?.content as BibleChapterParagraph[]
          this.setCurrentBibleBook(book.name)
        } catch (error) {
          console.error('Failed to fetch chapter content:', error)
          this.isLoading = false
          // Optionally, you could throw the error to be handled by the component
          throw error
        }
      }
      this.currentChapterContent = book.chapters[chapterIndex]
        .content as BibleChapterParagraph[]
      this.isLoading = false
    },
  },
})
